import * as React from 'react'

function EthLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z"
        fill="#627eea"
      />
      <g fill="#fff">
        <path d="M15.998 4v8.87l7.497 3.35z" fillOpacity={0.602} />
        <path d="M15.998 4L8.5 16.22l7.498-3.35z" />
        <path d="M15.998 22.352v6.027L23.5 18z" fillOpacity={0.602} />
        <path d="M15.998 28.379v-6.028L8.5 18z" />
        <path d="M15.998 20.701l7.497-4.353L15.998 13z" fillOpacity={0.2} />
        <path d="M8.5 16.348l7.498 4.353V13z" fillOpacity={0.602} />
      </g>
    </svg>
  )
}

export default EthLogo
