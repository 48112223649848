import * as React from 'react'
import { useUID } from 'react-uid'

function MUSDTLogo(props: React.SVGProps<SVGSVGElement>) {
  const id = useUID()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      width={props.width || 32}
      height={props.height || 32}
      {...props}
    >
      <defs>
        <linearGradient
          id={id}
          x1={-0.86}
          y1={200.31}
          x2={197.91}
          y2={2.66}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.19} stopColor="#50af95" />
          <stop offset={0.59} stopColor="#b7485d" />
          <stop offset={0.78} stopColor="#e51b44" />
        </linearGradient>
      </defs>
      <path
        d="M100 0a100 100 0 10100 100A100 100 0 00100 0z"
        fill={`url(#${id})`}
      />
      <path
        d="M109.92 105.36c-.55 0-3.43.23-9.83.23-5.1 0-8.72-.13-10-.23-19.71-.88-34.41-4.31-34.41-8.39s14.7-7.56 34.41-8.4V102c1.3.09 5 .32 10.07.32 6.12 0 9.18-.28 9.73-.32V88.57c19.67.88 34.37 4.32 34.37 8.4s-14.7 7.51-34.37 8.39zm0-18.18v-12h27.46V56.9H62.67v18.27h27.45v12C67.81 88.2 51 92.61 51 97.94s16.79 9.69 39.1 10.76v38.4H110v-38.44c22.26-1 39-5.43 39-10.72s-16.75-9.74-39.06-10.76zm0 0z"
        fill="#fff"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default MUSDTLogo
