import * as React from 'react'

function MaticLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z"
          fill="#8834FF"
        />
        <path
          d="M20.856 12.921c-.334-.19-.764-.19-1.146 0l-2.676 1.577-1.816 1.004-2.628 1.576c-.334.191-.764.191-1.146 0l-2.055-1.242a1.165 1.165 0 01-.573-1.003v-2.39c0-.382.19-.764.573-1.003l2.055-1.194c.334-.191.764-.191 1.146 0l2.055 1.242c.334.191.573.573.573 1.003v1.577l1.816-1.051v-1.625c0-.382-.191-.764-.573-1.003l-3.823-2.246c-.334-.19-.764-.19-1.147 0l-3.918 2.294a1.051 1.051 0 00-.573.955v4.492c0 .382.191.764.573 1.003l3.87 2.246c.335.191.765.191 1.147 0l2.628-1.53 1.816-1.05 2.628-1.53c.334-.19.764-.19 1.147 0l2.054 1.195c.335.191.574.574.574 1.004v2.389c0 .382-.192.764-.574 1.003l-2.007 1.195c-.334.19-.764.19-1.146 0l-2.055-1.195a1.165 1.165 0 01-.573-1.003v-1.53l-1.816 1.052v1.577c0 .382.191.764.573 1.003l3.87 2.246c.335.19.765.19 1.147 0l3.87-2.246c.335-.191.574-.573.574-1.003v-4.54c0-.382-.191-.764-.573-1.003l-3.87-2.246z"
          fill="#FFF"
        />
      </g>
    </svg>
  )
}

export default MaticLogo
