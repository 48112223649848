import * as React from 'react'
import { useUID } from 'react-uid'

function MMeldLogo(props: React.SVGProps<SVGSVGElement>) {
  const id1 = useUID()
  const id2 = useUID()
  const id3 = useUID()
  const id4 = useUID()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 200 200"
      width={props.width || 32}
      height={props.height || 32}
      {...props}
    >
      <defs>
        <linearGradient
          id={id1}
          x1={-0.86}
          y1={200.31}
          x2={197.91}
          y2={2.66}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.17} stopColor="#8247e5" />
          <stop offset={0.18} stopColor="#8347e4" />
          <stop offset={0.6} stopColor="#c92772" />
          <stop offset={0.78} stopColor="#e51b44" />
        </linearGradient>
        <linearGradient
          id={id2}
          x1={124.91}
          y1={-15.97}
          x2={125.1}
          y2={-16.16}
          gradientTransform="matrix(1 0 0 -1 0 69.86)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#d33651" stopOpacity={0} />
          <stop offset={0.29} stopColor="#d43650" stopOpacity={0.33} />
          <stop offset={0.65} stopColor="#d6364f" />
        </linearGradient>
        <linearGradient
          id={id3}
          x1={105.22}
          y1={-20.22}
          x2={126.6}
          y2={-12.2}
          gradientTransform="matrix(1 0 0 -1 0 69.86)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={0.06} stopColor="#fff" stopOpacity={0.94} />
          <stop offset={0.19} stopColor="#fff" stopOpacity={0.8} />
          <stop offset={0.35} stopColor="#fff" stopOpacity={0.56} />
          <stop offset={0.39} stopColor="#fff" stopOpacity={0.5} />
          <stop offset={0.52} stopColor="#fff" stopOpacity={0.29} />
          <stop offset={0.66} stopColor="#fff" stopOpacity={0.08} />
          <stop offset={0.73} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id={id4}
          x1={55.69}
          y1={-20.16}
          x2={77.2}
          y2={-12.09}
          xlinkHref={`#${id3}`}
        />
      </defs>
      <path
        d="M100 0a100 100 0 10100 100A100 100 0 00100 0z"
        fill={`url(#${id1})`}
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M100.05 104.8L83.36 57a8.46 8.46 0 00-8-5.52h-.06a8.5 8.5 0 00-8 5.57l-.24.72a2.36 2.36 0 00-.08.43 7.78 7.78 0 00-.13 1.38 7.45 7.45 0 00.27 2.42l8.27 23L92 132.75a8.64 8.64 0 0016.11 0l6.34-18.22-9.3-24.29M55.07 91.65H43.39a8.17 8.17 0 100 16.32h5.94l-6.85 19.44a8.14 8.14 0 005.33 10.38 8.81 8.81 0 002.72.45 8.5 8.5 0 008.06-5.57L65 114.53l-9.4-24.4zM165.31 99.84a8.34 8.34 0 00-8.51-8.16l-11.73-.05L132.88 57a8.57 8.57 0 00-8-5.57 8.45 8.45 0 00-8 5.52l-.36 1a1.86 1.86 0 000 .43 7.78 7.78 0 00-.14 1.38 7.24 7.24 0 00.38 2.43l8.13 22.84 16.77 47.65a8.58 8.58 0 0010.8 5.12 8.07 8.07 0 005.34-10.37L150.83 108h6a8.34 8.34 0 008.48-8.16z"
      />
      <path
        fill={`url(#${id2})`}
        fillRule="evenodd"
        d="M125.07 86.11L125.07 86.11 124.96 85.76 125.07 86.11z"
      />
      <path
        d="M116.4 58l-11.25 32.24 9.3 24.29 10.27-29.44-8.13-22.85a7.24 7.24 0 01-.38-2.43 7.78 7.78 0 01.14-1.38 1.43 1.43 0 01.05-.43z"
        fill={`url(#${id3})`}
        fillRule="evenodd"
      />
      <path
        d="M67 57.76L55.6 90.13l9.4 24.43 10.4-29.49L67.12 62a7.45 7.45 0 01-.37-2.46 7.78 7.78 0 01.13-1.38 2.36 2.36 0 01.12-.4z"
        fill={`url(#${id4})`}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default MMeldLogo
