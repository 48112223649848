import * as React from 'react'
import { useUID } from 'react-uid'

function MMaticLogo(props: React.SVGProps<SVGSVGElement>) {
  const id = useUID()

  return (
    <svg
      width="28px"
      height="28px"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <linearGradient
          x1="10.5783526%"
          y1="72.9127528%"
          x2="90.8780472%"
          y2="35.651585%"
          id={id}
        >
          <stop stopColor="#8834FF" offset="0%" />
          <stop stopColor="#E51B44" offset="100%" />
        </linearGradient>
      </defs>
      <g fillRule="nonzero" stroke="none" strokeWidth={1} fill="none">
        <path
          d="M14 28c7.732 0 14-6.268 14-14S21.732 0 14 0 0 6.268 0 14s6.268 14 14 14z"
          fill={`url(#${id})`}
          transform="translate(-574 -392) translate(143 370) translate(431 18) translate(0 4)"
        />
        <path
          d="M12.124 4.306c-.292-.167-.669-.167-1.003 0L8.78 5.686l-1.589.878-2.3 1.38c-.292.167-.668.167-1.003 0L2.09 6.857a1.02 1.02 0 01-.501-.878v-2.09c0-.335.167-.67.501-.879l1.798-1.045c.293-.167.67-.167 1.004 0l1.797 1.087c.293.167.502.502.502.878v1.38l1.589-.92V2.968c0-.334-.168-.669-.502-.878L4.933.125c-.292-.167-.669-.167-1.003 0L.502 2.132A.92.92 0 000 2.968v3.93c0 .335.167.67.502.878l3.386 1.965c.293.168.67.168 1.004 0L7.19 8.403l1.589-.92 2.3-1.337c.292-.167.668-.167 1.003 0L13.88 7.19c.293.167.502.502.502.878v2.09c0 .335-.167.67-.502.878l-1.756 1.046c-.292.167-.669.167-1.003 0l-1.798-1.046a1.02 1.02 0 01-.501-.878V8.822l-1.59.92v1.379c0 .334.168.669.502.878l3.387 1.965c.293.167.669.167 1.003 0l3.387-1.965a1.02 1.02 0 00.502-.878V7.149c0-.334-.168-.669-.502-.878l-3.387-1.965z"
          transform="translate(-574 -392) translate(143 370) translate(431 18) translate(0 4) translate(6.125 7)"
          fill="#FFF"
        />
      </g>
    </svg>
  )
}

export default MMaticLogo
