import { AssetType, ChainType } from 'crypto/interface'
import React from 'react'
import AdaLogo from 'ui/Icons/components/AdaLogo'
import EthLogo from 'ui/Icons/components/EthLogo'
import MAdaLogo from 'ui/Icons/components/MAdaLogo'
import MMaticLogo from 'ui/Icons/components/MMaticLogo'
import MMeldLogo from 'ui/Icons/components/MMeldLogo'
import MUSDTLogo from 'ui/Icons/components/MUSDTLogo'
import MaticLogo from 'ui/Icons/components/MaticLogo'
import MeldLogo from 'ui/Icons/components/MeldLogo'
import USDTLogo from 'ui/Icons/components/USDTLogo'

function AssetLogo({
  assetId,
  chain,
  ...props
}: { assetId: AssetType; chain: ChainType } & React.SVGProps<SVGSVGElement>) {
  switch (assetId) {
    case 'ada':
      return chain === 'cardano' ? (
        <AdaLogo {...props} />
      ) : (
        <MAdaLogo {...props} />
      )
    case 'meld':
      return chain === 'cardano' ? (
        <MeldLogo {...props} />
      ) : (
        <MMeldLogo {...props} />
      )
    case 'matic':
      return chain === 'polygon' ? (
        <MaticLogo {...props} />
      ) : (
        <MMaticLogo {...props} />
      )
    case 'eth':
      return <EthLogo {...props} />
    case 'usdt':
      return chain === 'polygon' ? (
        <USDTLogo {...props} />
      ) : (
        <MUSDTLogo {...props} />
      )
    default:
      return <MeldLogo {...props} />
  }
}

export default AssetLogo
