import * as React from 'react'

function USDTLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      width={props.width || 32}
      height={props.height || 32}
      {...props}
    >
      <path
        d="M100.03 0C44.77 0 0 44.77 0 100.03S44.77 200 100.03 200 200 155.25 200 100.03 155.23 0 100.03 0z"
        fill="#50af95"
      />
      <path
        d="M109.92 105.36c-.56.05-3.43.23-9.83.23-5.1 0-8.72-.14-9.97-.23-19.71-.88-34.41-4.31-34.41-8.39s14.7-7.56 34.41-8.39v13.4c1.3.09 4.96.32 10.06.32 6.12 0 9.18-.28 9.74-.32v-13.4c19.66.88 34.37 4.31 34.37 8.39s-14.7 7.51-34.37 8.39zm0-18.18V75.17h27.46V56.9H62.67v18.27h27.46v12.01c-22.31 1.02-39.1 5.43-39.1 10.76s16.79 9.69 39.1 10.76v38.4h19.85v-38.45c22.26-1.02 39-5.43 39-10.71s-16.74-9.74-39.05-10.76zm0 0"
        fill="#fff"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default USDTLogo
